export const TEST_STRIPE = "live";
export const SHOW_RECURRING_SUBSCRIPTIONS = false;
export const CURRENT_SUBSCRIPTION_YEAR = "2024";

export const PAYWALL_ROLE = "digital-subscriber";
// export const WP_URL = "http://3.27.33.185/";
// export const WP_URL = "http://54.79.42.216";
export const WP_URL = "https://blog.scplaybook.com.au";
export const WP_AUTH_URL = "https://blog.scplaybook.com.au";
// export const WP_AUTH_URL = "http://http://54.79.42.216";
// export const WP_CONTENT_URL = "https://scplaybook.com.au";
export const WP_CONTENT_URL = "http://13.239.14.106/";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
export const ADMIN_ROLES = ["administrator", "bbp_keymaster"];
export const BLOKE_PLAYLIST_ID = "PLhM_Qf_wr6RCvbyKFLnRofVI32A2zIMXJ";
export const STRIPE_MANAGE_URL =
  "https://billing.stripe.com/p/login/test_bIY9DH7hN2N8aac3cc";

export const HIGHLIGHT_COLORS = {
  base: "scpb-light-blue",
};

export const Static_Menu_Items = [
  {
    href: "/subscribe",
    label: "Subscribe",
    isAuth: false,
    hideForAuth: false,
    showForAll: true,
  },
  {
    href: "/account/myaccount",
    label: "Account",
    isAuth: true,
    hideForAuth: false,
    showForAll: false,
  },

  {
    href: "/login",
    label: "Log In",
    isAuth: false,
    hideForAuth: true,
    showForAll: false,
  },
  {
    href: "/logout",
    label: "Log Out",
    isAuth: true,
    hideForAuth: false,
    showForAll: false,
  },
  {
    href: "/about",
    label: "About Us",
    isAuth: false,
    hideForAuth: false,
    showForAll: true,
  },
  {
    href: "/contact",
    label: "Contact",
    isAuth: false,
    hideForAuth: false,
    showForAll: true,
  },
  {
    href: "/advertise",
    label: "Advertise with us",
    isAuth: false,
    hideForAuth: false,
  },
];

export const SOCIAL_LIST = [
  {
    href: "https://www.instagram.com/scplaybook1/",
    label: "instagram",
    icon: InstagramIcon,
  },
  {
    href: "https://www.facebook.com/scplaybook1",
    label: "facebook",

    icon: FacebookIcon,
  },
  {
    href: "https://twitter.com/scplaybook1",
    label: "twitter",
    icon: TwitterIcon,
  },
];

export const SCPB_LOGO = "/images/logos/Logo-Blue-White.svg";
export const SCPB_LOGOS = {
  "Small White": "/images/logos/logo_small_white.svg",
  "Small Blue Black": "/images/logos/logo_small_blue_black.svg",
  "Large Blue White": "/images/logos/Logo-Blue-White.svg",
  "Large Blue Black": "/images/logos/Logo-Blue-Black.svg",
};

// export const GTAG_ID = "G-YG4ZDG1XD0";
export const GTAG_ID = "G-1PN90W70EH";

export const Stripe_Product_Map = {
  prod_Pl1zHMOz4KBuN3: "Full Package",
  prod_OexJt4lB7VtFkJ: "AFL",
  prod_Pl1zIqNjw83uMQ: "NRL",
  prod_Pl21QOBaA76DAf: "BBL",
};

export const PREMIUM_CATEGORY = "subscriber-only";

export const NAV_HEIGHT = 48;
